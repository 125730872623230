import React from "react";


const FaqBanner = () => {
  return (
    <>
      <section id="faq" 
        className="breadcrumb__area include-bg breadcrumb__overlay faq_banner"
        style={{
          backgroundImage: `url("assets/img/bg/branch.webp")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content text-center p-relative z-index-1">
                <h3 className="breadcrumb__title text-white">Frequently Asked Questions</h3>
                {/* <div className="breadcrumb__list">
                  <span>
                    <Link to="/">Home</Link>
                  </span>
                  <span className="dvdr">:</span>
                  <span>faq</span> */}
                </div>
              </div>
            </div>
          </div>
        
      </section>
    </>
  );
};

export default FaqBanner;

const menu_data = [
  {
    id: 1,
    hasDropdown: false,
    title: 'Home',
    link: '/',
    
  },
  {
    id: 2,
    title: 'About',
    link: '#about'
  },
  {
    id: 3,
    hasDropdown: false,
    title: 'FAQ',
    link: '#faq'
  },
  {
    id: 4,
    hasDropdown: false,
    title: 'Services',
    link: '#service',
  },
  {
    id: 5,
    hasDropdown: false,
    title: 'Contact',
    link: '#contact',
    
  }
  
]

export default menu_data;
